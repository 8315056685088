@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DragonForcE";
  src: local("DragonForcE"), url("../fonts/DragonForcE.ttf") format("truetype");
  font-weight: bold;
}

.font-face-df {
  font-family: "DragonForcE";
}

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  color: black;
}

.flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.flip-both {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.card-zoom {
  @apply relative flex items-center justify-center m-3 overflow-hidden shadow-xl w-2/3 min-w-60 h-60 m-auto rounded-2xl;
}
.card-zoom-image {
  @apply absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover;
}
.card-zoom-text {
  @apply absolute text-3xl text-center p-20 font-black transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-80;
}
.card-zoom:hover .card-zoom-image {
  @apply scale-150;
}
.card-zoom:hover .card-zoom-text {
  @apply scale-100 opacity-100;
}
